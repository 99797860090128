<template>
  <div class="container">
    <div class="flex-box">
      <div class="box-left" :style="{ width: width }">
        <el-tree
          :data="treeData"
          show-checkbox
          default-expand-all
          node-key="id"
          ref="tree"
          highlight-current
          @node-click="handleNodeClick"
          :expand-on-click-node="false"
        >
        </el-tree>
      </div>
      <div class="box-right">
        <iframe
          v-if="show == 1"
          src="http://117.34.72.245:8088/808gps/open/player/video.html?lang=zh&devIdno=18920216033&account=cqhj&password=000000"
          frameborder="0"
          style="width: 100%; height: 100%"
        ></iframe>
        <iframe
          v-if="show == 2"
          src="http://117.34.72.245:8088/808gps/open/player/video.html?lang=zh&devIdno=13028858586&account=cqhj&password=000000"
          frameborder="0"
          style="width: 100%; height: 100%"
        ></iframe>
        <!-- <iframe
          style="width: 50%;height:300px"
          src="http://117.34.72.245:8088/808gps/open/hls/index.html?lang=zh&devIdno=13028858586&jsession=9ff825aeb3954faeb2c8454c4625a418&channel=1"
          frameborder="0"
        ></iframe>
        <iframe
          style="width: 50%;height:300px"
          src="http://117.34.72.245:8088/808gps/open/hls/index.html?lang=zh&devIdno=13028858586&jsession=9ff825aeb3954faeb2c8454c4625a418&channel=2"
          frameborder="0"
        ></iframe>
        <iframe
          style="width: 50%;height:300px"
          src="http://117.34.72.245:8088/808gps/open/hls/index.html?lang=zh&devIdno=13028858586&jsession=9ff825aeb3954faeb2c8454c4625a418&channel=3"
          frameborder="0"
        ></iframe>
        <iframe
          style="width: 50%;height:300px"
          src="http://117.34.72.245:8088/808gps/open/hls/index.html?lang=zh&devIdno=13028858586&jsession=9ff825aeb3954faeb2c8454c4625a418&channel=0"
          frameborder="0"
        ></iframe> -->
        <!-- <div class="video-box">
          <video
            class="video"
            src="https://junhe.oss-cn-beijing.aliyuncs.com/homeVideo.mp4"
            muted
            loop
            controls
            v-for="item in 16"
            :key="item"
          ></video>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import {
  StandardApiAction_login,
  StandardApiAction_queryUserVehicle,
  StandardApiAction_getDeviceByVehicle,
  getvideo
} from "@/api/tree_map";
export default {
  data() {
    return {
      width: "260px",
      jsession: "",
      vehiIdno: [],
      treeData: [
        {
          id: 1,
          label: "监控中心",
          children: []
        }
      ],
      show: 1
    };
  },
  mounted() {
    this.StandardApiAction_login();
  },
  methods: {
    handleNodeClick(e) {
      console.log(e);
      if (e.id == "18920216033") {
        this.show = 1;
      } else {
        this.show = 2;
      }
    },
    // 登录
    async StandardApiAction_login() {
      let data = {
        account: "cqhj",
        password: "000000"
      };
      let res = await StandardApiAction_login(data);
      this.jsession = res.jsession;
      this.StandardApiAction_queryUserVehicle();
    },
    // 获取车辆信息
    async StandardApiAction_queryUserVehicle() {
      let data = {
        jsession: this.jsession
      };
      let res = await StandardApiAction_queryUserVehicle(data);

      this.vehiIdno = [];
      res.vehicles.forEach(el => {
        this.vehiIdno.push(el.nm);
      });

      this.vehiIdno = this.vehiIdno.toString();
      this.StandardApiAction_getDeviceByVehicle();
    },
    // 获取设备号
    async StandardApiAction_getDeviceByVehicle() {
      let data = {
        jsession: this.jsession,
        vehiIdno: this.vehiIdno
      };

      let res = await StandardApiAction_getDeviceByVehicle(data);
      console.log(res);
      this.devIdno = [];
      res.devices.forEach(el => {
        this.devIdno.push(el.did);
        this.treeData[0].children.push({
          id: el.did,
          label: el.vid,
          children: []
        });
      });
      this.$nextTick(() => {
        this.$refs.tree.setCheckedNodes(this.treeData);
        // this.video("18920216033");
      });
    },
    // 获取视频
    async video(vehiIdno) {
      let data = {
        AVType: 1,
        jsession: this.jsession,
        DevIDNO: vehiIdno
        // Channel: "",
        // Stream: ""
      };
    
      let res = await getvideo(data);
      console.log(res);
    }
  }
};
</script>

<style lang="scss" scoped>
.flex-box {
  display: flex;
  height: 100vh;
  padding-top: 120px;
  .box-left {
    height: 100%;
    padding: 10px 10px;
    background: #fff;
    overflow-y: auto;
    transition: width 0.28s;
    position: relative;
  }
  .box-right {
    width: 100%;
    height: 100%;
  }
}

.video-box {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(4, 25%);
  grid-template-rows: repeat(4, 25%);
  // grid-row-gap: 10px;
  // grid-column-gap: 10px;
  .video {
    border: 2px solid #ccc;
    width: 100%;
    height: 100%;
  }
}
</style>
